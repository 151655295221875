import React, { useState } from 'react'

import { Link } from 'gatsby'
import { logoType } from '../../../helper'
import Autosuggest from 'react-autosuggest'

const SearchCombinationPlatformBlock = ({ introSearch, commonHeadline, commonSearch, platforms }) => {

    const [value, setValue] = useState('')
    const [platformSelected, setPlatformSelected] = useState()
    const [suggestions, setSuggestions] = useState([])

    const getSuggestions = value => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;
        return inputLength === 0 ? [] : platforms.filter(platform => {
            if (platform.name.toLowerCase().indexOf(value.toLowerCase()) >= 0) return true
            return false
        })
    };

    const getSuggestionValue = suggestion => suggestion.name;

    const onChange = (event, { newValue }) => {
        setValue(newValue)
    }

    const onSuggestionsFetchRequested = ({ value }) => {
        setSuggestions(getSuggestions(value))
    };

    const onSuggestionsClearRequested = () => {
        setSuggestions([])
    };

    const onSuggestionHighlighted = ({ suggestion }) => {
        if (suggestion) {
            const platformSuggestion = {
                'platformName': suggestion.name,
                'combinationSlug': suggestion.slug
            }
            setPlatformSelected(platformSuggestion)
        } else {
            setPlatformSelected(platformSelected)
        }
    }

    const renderSuggestion = suggestion => (
        <div className="result-item">
            {
                logoType(
                    suggestion.svg,
                    suggestion.image,
                    suggestion.useImage
                )
            }
            {suggestion.name}
        </div>
    );

    const inputProps = {
        placeholder: "GTM, Google Analytics, Facebook Ads, Microsoft Ads...",
        value,
        onChange: onChange,
    };

    const handleClickSearch = () => {
        if (typeof window != 'undefined') {
            window.location.assign(platformSelected.combinationSlug)
        }
    }

    const onSuggestionSelected = () => {
        if (typeof window != 'undefined') {
            window.location.assign(platformSelected.combinationSlug)
        }
    }

    return (
        platforms.length > 0 && (
            <div className="form-wrapper combination search">
                <div className="intro-search">
                    <p>{introSearch}</p>
                </div>
                <div className="form">
                    <div className="combobox-wrapper">
                        <Autosuggest
                            suggestions={suggestions}
                            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                            onSuggestionsClearRequested={onSuggestionsClearRequested}
                            onSuggestionHighlighted={onSuggestionHighlighted}
                            getSuggestionValue={getSuggestionValue}
                            renderSuggestion={renderSuggestion}
                            inputProps={inputProps}
                            alwaysRenderSuggestions={false}
                            onSuggestionSelected={onSuggestionSelected}
                        />
                        <button className="button" onClick={handleClickSearch} disabled={platformSelected && inputProps.value === platformSelected.platformName ? '' : 'disabled'} >Search</button>
                    </div>
                </div>
                <div className="common-search">
                    <div className="headline">
                        <p>{commonHeadline}</p>
                    </div>
                    <ul>
                        {
                            commonSearch && commonSearch.map((platformCommonSearch, index) => {
                                if (platformCommonSearch.slug) {
                                    return (
                                        <li key={index}><Link to={platformCommonSearch.slug}>{platformCommonSearch.name}</Link></li>
                                    )
                                }
                                return (
                                    <li key={index}>{platformCommonSearch.name}</li>
                                )
                            })
                        }
                    </ul>
                </div>
            </div>
        )

    )
}

export default SearchCombinationPlatformBlock 
