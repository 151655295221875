import React, { Fragment } from 'react'
import TabCustomPostBlock from '../../../Blocks/TabCustomPostBlock'
import HeadlineAndBodyTextBlock from '../../../Blocks/HeadlineAndBodyTextBlock'
import ContentRowBlock from '../../../Blocks/ContentRowBlock'
import CreateAccountBlock from '../../../Blocks/CreateAccountBlock'
import ComingSoonBlock from '../../../Blocks/ComingSoonBlock'
import AccordionBlock from '../../../Blocks/AccordionBlock'
import SearchCombinationPlatformBlock from '../../../Blocks/search-block/SearchCombinationPlatformBlock'
import { getSlug, replaceStr } from '../../../../helper'
import ImportantNoticeBlock from '../../../Blocks/ImportantNoticeBlock'
import { LISTENER_USECASE_SHORTCODE, LISTENER_SHORTCODE } from '../../../../constants'
const MainListenerSection = ({
     tabs,
     introBodyText,
     rows,
     status,
     comingSoonMessage,
     faqs,
     searchPlatform,
     listenerName,
     importantNotice,
     listenerType,
     nameOfEmailList,
}) => {
     console.log('rows', rows)
     return (
          <section className="main-content-wrapper">
               <div className="container">
                    <div className="tab-content">
                         <TabCustomPostBlock listSlug={tabs.listSlug} customPostType={tabs.customPostType} hideTabs={tabs.hideTabs} />
                    </div>
                    <div className="main-content">
                         <div className="intro-body">
                              <div className="row">
                                   <div className="col-lg-12">
                                        <HeadlineAndBodyTextBlock headlines={introBodyText.headlines} body={introBodyText.body} />
                                   </div>
                              </div>
                              {importantNotice && importantNotice.showNotice && (
                                   <ImportantNoticeBlock
                                        headlineNotice={importantNotice.headline}
                                        detailNotice={importantNotice.detail}
                                        color="red"
                                   />
                              )}
                         </div>
                         {rows && (
                              <div className="rows-content">
                                   <div className="row">
                                        {rows.map((row, index) => {
                                             if (Array.isArray(row.selectedUseCase) && row.selectedUseCase.length > 0) {
                                                  const {
                                                       contentSections,
                                                       listenerPlatformCustomization,
                                                       mainElements,
                                                  } = row.selectedUseCase[0].cfUseCase
                                                  const usecaseSlug = getSlug(row.selectedUseCase[0].uri)
                                                  listenerName.push(mainElements.usecaseNamePlural, mainElements.usecaseNameSingular)
                                                  let h4 = row.customizeContent
                                                       ? row.customHeadline
                                                       : listenerPlatformCustomization.listenerMergedHeadline
                                                  let description = row.customizeContent
                                                       ? row.customDescription
                                                       : listenerPlatformCustomization.listenerMergedDescription
                                                  row = {
                                                       layout: 'Video Right',
                                                       lengthOfVideoMessage: contentSections.lengthOfVideoMessage,
                                                       videoEmbedCode: contentSections.videoEmbedCode,
                                                       h4: replaceStr(LISTENER_USECASE_SHORTCODE, listenerName, h4),
                                                       description: replaceStr(LISTENER_USECASE_SHORTCODE, listenerName, description),
                                                       title: row.customizeContent
                                                            ? row.customButtonText
                                                            : listenerPlatformCustomization.listenerButtonText,
                                                       url: `/${usecaseSlug}/${mainElements.slug}`,
                                                  }
                                                  listenerName.splice(2, 2)
                                             } else {
                                                  row = {
                                                       ...row,
                                                       h4: replaceStr(LISTENER_SHORTCODE, listenerName, row.h4),
                                                       description: replaceStr(LISTENER_SHORTCODE, listenerName, row.description),
                                                  }
                                             }
                                             return (
                                                  <Fragment key={index}>
                                                       <div className="row-item">
                                                            <ContentRowBlock values={row} />
                                                       </div>
                                                       {index === 1 && status === 'Coming Soon' && (
                                                            <div className="col-lg-12">
                                                                 <div className="form-content-row">
                                                                      <CreateAccountBlock />
                                                                 </div>
                                                            </div>
                                                       )}
                                                  </Fragment>
                                             )
                                        })}
                                   </div>
                              </div>
                         )}
                    </div>
                    {searchPlatform && (
                         <SearchCombinationPlatformBlock
                              introSearch={searchPlatform.introSearch}
                              commonHeadline={searchPlatform.commonHeadline}
                              commonSearch={searchPlatform.commonSearch}
                              platforms={searchPlatform.platforms}
                              slugIntegration={searchPlatform.slugIntegration}
                              listenerType={listenerType}
                         />
                    )}
                    <div>{faqs && <AccordionBlock faqs={faqs} buttonText="Load More" showButton={true} />}</div>
                    {status === 'Coming Soon' ? (
                         <ComingSoonBlock comingSoonMessage={comingSoonMessage} nameOfEmailList={nameOfEmailList} />
                    ) : (
                         <CreateAccountBlock customClass="get-start" />
                    )}
               </div>
          </section>
     )
}

export default MainListenerSection
