import React from 'react'
import RelatedListenerBlock from '../../../Blocks/related-block/RelatedListenerBlock'
import SearchListenerBlock from '../../../Blocks/search-block/SearchListenerBlock'

const RelatedListenerSection = ({ relatedListener, alternatives, descriptionAboveSearch, instructionsAboveSearch, listeneNamerCurrent }) => {
    return (
        <section className="related-listener-section">
            <div className="container">
                {
                    relatedListener && relatedListener.showRelated && (
                        <RelatedListenerBlock
                            titleH3={relatedListener.titleH3}
                            description={relatedListener.description}
                            relatedListeners={relatedListener.relatedListeners}
                        />
                    )
                }
                {

                    <SearchListenerBlock
                        headline={descriptionAboveSearch ? descriptionAboveSearch : 'Search for other listeners'}
                        subHeadline={instructionsAboveSearch ? instructionsAboveSearch : 'Enter the name of an app you use'}
                        listeneNamerCurrent={listeneNamerCurrent}
                    />
                }
                {
                    alternatives && alternatives.showAlternatives && (
                        <RelatedListenerBlock
                            titleH3={alternatives.titleH3}
                            description={alternatives.description}
                            relatedListeners={alternatives.relatedListeners}
                        />
                    )
                }
            </div>
        </section>
    )
}

export default RelatedListenerSection
