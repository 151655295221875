import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import Layout from "../components/layout"
import SEO from "../components/seo"
import ListenerHelp from '../components/general/single-listener-cpt/ListenerHelp'
import BreadcrumbSection from '../components/breadcrumb'
import { getSlug, replaceStr } from '../helper'
import { LISTENER_SHORTCODE } from '../constants'
import PopupSchedule from '../components/popupSchedule/PopupSchedule';


const CptListenerHelpTemplate = ({ data, location }) => {
    const { tabs, mainElements, relatedAlternative } = data.wpCptListener.cfListener
    const { titleTag, metaDescription, canonicalTagOverride, robotsDirective } = data.wpCptListener.cfListener.tabs.helpSeoFields
    const { ogTitle, ogDescription, ogImage, ogType, twitterCard } = data.wpCptListener.cfListener.tabs.helpDefaultSeoFields
    const listenerSlug = getSlug(data.wpCptListener.uri)
    const listernerCptSlug = `${listenerSlug}/${mainElements.slug}`
    const breadcrumbItems = [
        {
            name: `Listeners`,
            url: listenerSlug,
        },
        {
            name: mainElements.breadcrumbValue,
            url: listernerCptSlug,
        },
        {
            name: `Help`,
        }
    ]

    const listenerName = [mainElements.listenerNamePlural, mainElements.listenerNameSingular]

    if (mainElements.hideTabs && mainElements.hideTabs.includes('Help')) {
        if (typeof window !== "undefined") {
            window.location.href = `/${listernerCptSlug}`
        }
    }

    const canonical = process.env.GATSBY_SITE_URL + location.pathname

    const [showSchedule, setShowSchedule] = useState(false)

    useEffect(() => {
        if (showSchedule) {
            document.querySelector('html').setAttribute('style', 'overflow:hidden')
        } else {
            document.querySelector('html').setAttribute('style', 'overflow:initial')
        }
    }, [showSchedule])

    useEffect(() => {
        if (window.location.href.includes("view-demo=1")) {
            setShowSchedule(true);
        }
    }, []);

    return (
        <Layout>
            <SEO
                title={replaceStr(
                    LISTENER_SHORTCODE,
                    listenerName,
                    titleTag
                )}
                description={replaceStr(
                    LISTENER_SHORTCODE,
                    listenerName,
                    metaDescription
                )}
                canonical={canonicalTagOverride || canonical}
                robotsDirective={robotsDirective}
                ogTitle={ogTitle}
                ogDescription={ogDescription}
                ogImage={ogImage}
                ogType={ogType}
                twitterCard={twitterCard}
                classBody="listener-help"
                breadcrumbItems={breadcrumbItems}
            />
            <BreadcrumbSection breadcrumbItems={breadcrumbItems} />
            <PopupSchedule showSchedule={showSchedule} setShowSchedule={setShowSchedule} />
            <ListenerHelp
                tabs={tabs}
                mainElements={mainElements}
                relatedAlternative={relatedAlternative}
                wpCptListener={data.wpCptListener}
                listenerName={listenerName}
            />
        </Layout>
    )
}

export default CptListenerHelpTemplate;

export const pageQuery = graphql`
    query PageHelpBySlug($slugTab: String!, $slugListener: String!) {
        wpCptListener(cfListener: {mainElements: {slug: {eq: $slugListener}}, tabs: {helpSlug: {eq: $slugTab}}}){
            cfListener {
                mainElements {
                    breadcrumbValue
                    comingSoonMessage
                    nameOfEmailList
                    hideTabs
                    listenerExcerpt
                    listenerNamePlural
                    listenerNameSingular
                    logoFileSvg
                    nameOfEmailList
                    slug
                    status
                    useImageInsteadOfSvg
                    descriptionAboveSearch
                    instructionsAboveSearch
                    createAccountLiveStatus {
                        createAccountHeadline
                        createAccountSubHeadline
                    }
                    createAccountComingSoonStatus {
                        createAccountHeadline
                        createAccountSubHeadline
                    }
                    logoFileImage {
                        ...WordpressImageFields
                    }
                    iconFileSvg
                    iconUseImageInsteadOfSvg
                    iconFileImage {
                        ...WordpressImageFields
                    }
                }
                relatedAlternative {
                    relatedDescription
                    relatedH3
                    showRelated
                    showAlternatives
                    overrideAlternativeDescription
                    customAlternativeDescription
                    alternativeListeners {
                        ...WordpressCPTListenerRelatedFields
                    }
                    relatedListeners {
                        ...WordpressCPTListenerRelatedFields
                    }
                }
                tabs {
                    helpSlug
                    usecasesSlug
                    datalayersamplesSlug
                    trackingintegrationsSlug
                    helpH1
                    helpH2
                    helpH3
                    helpIntro
                    helpRows {
                        codeExample
                        description
                        h4
                        importantNotice
                        layout
                        lengthOfVideoMessage
                        svg
                        videoEmbedCode
                        displayImportantNotice
                        image {
                            ...WordpressImageFields
                        }
                    }
                    faqs {
                        ...WordpressCPTFaqFields
                    }
                    helpDefaultSeoFields {
                        ogDescription
                        ogTitle
                        ogType
                        twitterCard
                        ogImage {
                            ...WordpressImageFields
                        }
                    }
                    helpSeoFields {
                        canonicalTagOverride
                        metaDescription
                        robotsDirective
                        titleTag
                    }
                }
            }
            uri
            
        }
    }
`
